import React from 'react'
import './Button.css'

const Button = ({ onClick }) => {
  return (
    <button className='button-landing' onClick={onClick}>
      Download Now
    </button>
  )
}

export default Button
