import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import VanillaTilt from 'vanilla-tilt'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import googleplay from '../assets/googlestore.svg'
import applestore from '../assets/appstore.svg'
import landingImages from '../assets/landingImages.png'
import logo from '../assets/logo.png'
import avatar from '../assets/avatar.png'
import './LandingPage.css'
import Button from '../components/Button'

function Tilt (props) {
  const { options, ...rest } = props
  const tilt = useRef(null)

  useEffect(() => {
    VanillaTilt.init(tilt.current, options)
  }, [options])

  return (
    <div ref={tilt} {...rest}>
      <img src={avatar} className=''></img>
      <img src={avatar} className='tilt-img-2'></img>
    </div>
  )
}

/* 
  {
    reverse:           false,  // reverse the tilt direction
    max:               35,     // max tilt rotation (degrees)
    perspective:       1000,   // Transform perspective, the lower the more extreme the tilt gets.
    scale:             1,      // 2 = 200%, 1.5 = 150%, etc..
    speed:             300,    // Speed of the enter/exit transition
    transition:        true,   // Set a transition on enter/exit.
    axis:              null,   // What axis should be disabled. Can be X or Y.
    reset:             true,   // If the tilt effect has to be reset on exit.
    easing:            "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
    glare:             false,   // if it should have a "glare" effect
    "max-glare":       1,      // the maximum "glare" opacity (1 = 100%, 0.5 = 50%)
    "glare-prerender": false   // false = VanillaTilt creates the glare elements for you, otherwise
                               // you need to add .js-tilt-glare>.js-tilt-glare-inner by yourself
} */

const options = {
  scale: 1.1,
  transition: true,
  speed: 1,
  reset: false,
  max: 40,
  reverse: true,
  easing: 'cubic-bezier(.27,.16,.16,.82)',
  'reset-to-start': false,
  'full-page-listening': true
}

const LandingPage = () => {
  return (
    <div className='wrapper'>
      <div className='landingpage-container'>
        <div className='landingpage-wrapper'>
          <header className='App-header-landing'>
            <div className='button-container'>
              <img src={logo} className='logo-img'></img>
            </div>
          </header>
          <div className='title-wrapper'>
            <h1 className='app-title'>
              Que no se te enfríe más la comida pensando qué ver.
            </h1>
            <p className='title-paragraph'>
              Películas, series, streamings y mucho más...
            </p>
            <p className='title-paragraph'>
              Wishlist con tus amigos, compañeros, família...
            </p>
            <div className='buttons-wrapper'>
              <Button></Button>
            </div>
            <div className='appstore-wrapper'>
              <img src={googleplay} className='appstore-wrapper-img'></img>
              <img src={applestore} className='appstore-wrapper-img'></img>
            </div>
          </div>
        </div>
      </div>
      <div className='graphics-container'>
        <Tilt className='box' options={options} />
      </div>
    </div>
  )
}

export default LandingPage
